export default {
  tableFields: [
    /* {
           name: '__component:badge-column',
           title: '',
           dataClass: 'text-center',
          // width: '4%'
         }, */
    {
      name: 'device.name',
      title: 'Dispositivo',
      sortField: 'device.name',
      // width: '34%'
    },
    /* {
            name: 'virtual_credit',
            title: 'Virtuales',
            sortField: 'virtual_credit',
            //  width: '34%'
        }, */
    {
      name: 'standard_credit',
      title: 'Normal',
      sortField: 'standard_credit',
      //  width: '34%'
    },

    {
      name: 'user_ktag.codektag',
      title: 'Customer',
      sortField: 'user_ktag.codektag',
      // width: '34%'
    },
    {
      name: 'customer.name',
      title: 'Nombre cliente',
      sortField: 'customer.name',
      // width: '34%'
    },

    {
      name: 'staff.code',
      title: 'Staff',
      sortField: 'staff.code',
      // width: '34%'
    },
    {
      name: 'staff.name',
      title: 'Nombre Staff',
      sortField: 'staff.name',
      // width: '34%'
    },
    {
      name: 'timestamp_formated',
      title: 'Fecha',
      sortField: 'timestamp_formated',
      // width: '34%'
    },
    {
      name: '__slot:status',
      title: 'Estatus',
      titleClass: 'center aligned',
      dataClass: 'center aligned',
    },
    {
      name: '__slot:actions',
      title: 'Detalles',
      titleClass: 'center aligned',
      dataClass: 'center aligned',
    },

  ],
  sortFunctions: {
    'name': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    },

  }
}
